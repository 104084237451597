
        <template>
          <div class="vc-snippet-doc">
            <h2>Icon 图标</h2>
<p>提供了一套常用的图标集合。</p>
<h3>使用方法</h3>
<p>直接通过设置类名为 <code>el-icon-iconName</code> 来使用即可。例如：</p>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-0 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">i</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"ixu-icon-template"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">i</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">i</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"ixu-icon-liuyan"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">i</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">i</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"ixu-icon-zonglan"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">i</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">el-button</span> <span class="hljs-attr">type</span>=<span class="hljs-string">"primary"</span> <span class="hljs-attr">icon</span>=<span class="hljs-string">"ixu-icon-search"</span>&gt;</span>搜索<span class="hljs-tag">&lt;/<span class="hljs-name">el-button</span>&gt;</span>

</code></pre>
    </div>
                </vc-snippet> <h3>图标集合</h3>
<ul class="icon-list">
  <li v-for="name in $icon" :key="name">
    <span>
      <i :class="'ixu-icon-' + name"></i>
      <span class="icon-name">{{'ixu-icon-' + name}}</span>
    </span>
  </li>
</ul>

          </div>
        </template>
        <script>
           
           export default {
           name: 'vc-component-doc',
           components: {
            "vc-snippent-0":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('i',{staticClass:"ixu-icon-template"}),_vm._v(" "),_c('i',{staticClass:"ixu-icon-liuyan"}),_vm._v(" "),_c('i',{staticClass:"ixu-icon-zonglan"}),_vm._v(" "),_c('el-button',{attrs:{"type":"primary","icon":"ixu-icon-search"}},[_vm._v("搜索")])],1)}
var staticRenderFns = []

          const exportJavaScript = {};
           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })()
           }
         }
       </script>
       <style lang='scss'>
         
       </style>